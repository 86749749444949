import styleJson from '../static/maps/style.json';

export default class MembersMap {
  constructor() {
    this.$map = $('.members-map');
    this.$filters = $('.sector-check');
    this.markers = [];
    this.marker = '/wp-content/themes/BBDW/dist/static/maps/marker.png';
    this.cluster = '/wp-content/themes/BBDW/dist/static/maps/M'; // misses .png on purpose. Google adds that for us :)
  }

  init() {
    if (!this.$map.length) {
      return;
    }

    const mapType = new google.maps.StyledMapType(styleJson);
    this.map = new google.maps.Map(this.$map[0], {
      center: { lat: 52.47117918608482, lng: 4.668784338623055 },
      zoom: 15,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      zoomControl: true,
      disableDefaultUI: true,
      mapTypeControlOptions: {
        mapTypeIds: ['roadmap', 'satellite', 'hybrid', 'terrain', 'styled_map'],
      },
    });
    this.map.mapTypes.set('styled_map', mapType);
    this.map.setMapTypeId('styled_map');

    if (!window.members.all.length) {
      return;
    }

    this.oms = new OverlappingMarkerSpiderfier(this.map, {});
    this.oms.legColors.usual.styled_map = '#4a6b93';
    this.oms.legColors.highlighted.styled_map = '#4a6b93';

    this.markers = window.members.all.map((member) => {
      const memberData = member.company;
      let content = `<strong>${memberData.name}</strong><br>`;

      if (memberData.address) {
        content += `
          ${memberData.address.street || ''} ${memberData.address.number || ''} ${memberData.address.unit || ''} <br>
          ${memberData.address.city || ''}<br>
        `;
      }

      if (memberData.contact) {
        if (memberData.contact.website) {
          content += `
            <a target="_blank" href="${memberData.contact.website || ''}">${memberData.contact.website || ''}</a><br>
          `;
        }
        if (memberData.contact.phone) {
          content += `
            <a href="tel:${memberData.contact.phone || ''}">${memberData.contact.phone || ''}</a>
          `;
        }
      }

      const infoWindow = new google.maps.InfoWindow({
        content,
      });

      const marker = new google.maps.Marker({
        title: memberData.name,
        member,
        infoWindow,
        position: {
          lat: Number(memberData.address.latitude),
          lng: Number(memberData.address.longitude),
        },
        icon: this.marker,
      });

      this.oms.addMarker(marker);

      google.maps.event.addListener(marker, 'spider_click', () => {
        this.markers.map(x => x.infoWindow.close(this.map, x));
        infoWindow.open(this.map, marker);
      });

      return marker;
    });

    this.clusterMarkers();
    this.filters();
  }

  clusterMarkers(markers = this.markers) {
    this.markerCluster = new MarkerClusterer(this.map, markers, {
      imagePath: this.cluster,
      gridSize: 40,
      maxZoom: 18,
    });
  }

  filters() {
    this.$filters.change(() => {
      const checked = this.$filters.filter(':checked');
      const ids = checked.toArray().map(i => i.value);

      this.markerCluster.clearMarkers();
      this.oms.forgetAllMarkers();

      const visible = [];
      this.markers.forEach((i) => {
        if (ids.includes(i.member.company.sector.key) || ids.length === 0) {
          this.oms.addMarker(i); // shows the marker
          visible.push(i);
        } else {
          this.oms.removeMarker(i); // hides the marker
        }
      });
      
      this.clusterMarkers(visible);
    });
  }
}
