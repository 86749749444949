import 'core-js/stable';
import Sticky from 'sticky-js';
import 'regenerator-runtime/runtime';
import MembersMap from './members-map';
import MembersList from './members-list';

const $ = window.jQuery;

class Main {
  /**
   * Sets necessary variables for this class
   * that are used throughout
   */
  constructor() {
    this.name = 'BBDW';
    this.mapper = new MembersMap();
    this.membersList = new MembersList();

    this.videoMuted = true;

    this.$contentButtons = $('.tab-buttons button');
    this.$contentSections = $('.content-tabs section');
    this.$mobileSelect = $('select[name=mobile-select]');
    this.$loadingOverlay = $('.loading-overlay');
  }

  /**
   * Initializes all needed functions
   */
  init() {
    $('html').attr('class', 'bbdw-js');

    this.mapper.init();
    this.membersList.init();
    this.initStickyTabs();
    this.removeLoadingOverlay();
    Main.toggleMembersView();

    this.sticky = new Sticky('.sticky-tabs .slider');
    this.stickySelect = new Sticky('.sticky-tabs .selector');
  }

  initStickyTabs() {
    $('select[name=mobile-select]').change(() => {
      this.showTab(this.$mobileSelect.val());
    });

    this.$contentButtons.click((e) => {
      const button = e.currentTarget;
      $('body,html').animate({
        scrollTop: $('.content-tabs').offset().top - $('header#top').height(),
      }, 250);

      this.showTab(button.dataset.template);
    });

    this.$contentButtons.first().addClass('active');
    this.$contentSections.first().addClass('active');
  }

  removeLoadingOverlay() {
    this.$loadingOverlay.hide();
  }

  showTab(id) {
    this.$mobileSelect.val(id);

    this.$contentButtons.removeClass('active');
    this.$contentButtons.filter(`[data-template=${id}]`).addClass('active');

    this.$contentSections.removeClass('active');
    this.$contentSections.filter(`[data-template=${id}]`).addClass('active');
  }

  static toggleMembersView() {
    $('.toggle-view button[data-toggle]').on('click', (e) => {
      const target = $(e.currentTarget.dataset.toggle);
      
      $('.members-list, .members-map').hide();
      target.show();

      // Button activeness
      $('.toggle-view button[data-toggle').removeClass('active');
      $(e.currentTarget).addClass('active');
    });
  }
}

$(() => {
  const m = new Main();
  m.init();
});
