/**
 * This class should:
 * 1. Read about 20 members for a start
 * 2. Create HTML element for each member
 * 3. Add member HTML element to ```.members-list```
 * 4. Observe scrolling and load 20 more members
 * 5. Repeat 2 through 4
 */

export default class MembersList {
  constructor() {
    this.offset = 0;
    this.loaded = [];
    this.members = window.members.all;
    this.loadObserver = this.setObserver();
    this.mobileMediaQuery = window.matchMedia('(max-width: 768px)');

    // Elements
    this.listEl = document.querySelector('.members-list');

    if (this.listEl) {
      this.triggerEl = this.listEl.querySelector('.members-list-trigger');
      this.filtersEl = document.querySelectorAll('.sector-check');
    }
  }
  
  init() {
    if (!this.listEl) {
      return;
    }

    // Order members alphabetically before using the list
    this.members.sort((a, b) => {
      const textA = a.company.name.toUpperCase();
      const textB = b.company.name.toUpperCase();
      let sort = 0;

      sort = textA < textB ? -1 : 1;

      return sort;
    });

    /**
     * Load our members, start with 21 members to fill out the grid
     */
    this.loadMembers(21);

    /**
     * Observe the trigger element and load more members
     * if it scrolls into view
     */
    if (this.mobileMediaQuery.matches) {
      this.triggerEl.addEventListener('click', () => this.loadMembers(60));
      this.loadObserver.disconnect();
    } else {
      this.loadObserver.observe(this.triggerEl);
    }

    this.mobileMediaQuery.addEventListener('change', (query) => {
      if (query.matches) {
        this.triggerEl.addEventListener('click', () => this.loadMembers(60));
        this.loadObserver.disconnect();
      } else {
        this.loadObserver.observe(this.triggerEl);
      }
    });

    /**
     * Listen for filter change
     */
    this.filtersEl.forEach((filterEl) => {
      filterEl.addEventListener('change', () => {
        this.filterMembers();
      });
    });
  }

  /**
   * Retrieve part of members from full list
   * 
   * @param {Number} offset
   * @param {Number} amount 
   * @returns {Array}
   */
  getMembers(offset = 0, amount = 20, mindFilters = true) {
    let memberOffset = offset;
    const end = memberOffset + amount;
    let { members } = this;

    if (mindFilters) {
      const checkedSectors = this.getCheckedSectors();
      
      if (checkedSectors.length) {
        members = members.filter(member => checkedSectors.includes(member.company.sector.key));
        if (members.length <= memberOffset) {
          memberOffset = 0;
        }
      }
    }

    return members.slice(memberOffset, end);
  }

  /**
   * Create node for specified member
   * 
   * @param {Object} member 
   * @returns {Node}
   */
  static createEl(member) {
    const { company } = member;
    const el = document.createElement('div');
    el.classList.add('member');
    el.dataset.sectorKey = company.sector.key;
    el.dataset.memberId = member.id;

    let markup = '';
    if (company.logo) {
      markup += `<div class="member-image">
        <img src="${company.logo}">
      </div>`;
    } else {
      markup += `<div class="member-image">
        <span class="member-initial">${company.name[0].toUpperCase()}</span>
      </div>`;
    }

    markup += '<div class="member-content">';
    if (company.name) {
      markup += `<h4 class="member-title">${company.name}</h4>`;
    }

    if (company.contact && company.contact.website) {
      markup += `<a href="${company.contact.website}" target="_blank">Naar website</a>`;
    }
    markup += '</div>';

    el.innerHTML = markup;
    return el;
  }

  /**
   * Add member node to the member list
   * 
   * @param {Node} el 
   * @returns {Boolean}
   */
  addMemberElToList(el) {
    this.listEl.insertBefore(el, this.triggerEl);
    return true;
  }

  /**
   * Add specified amount of members to member list view
   * 
   * @param {Number} amount 
   * @returns {Boolean}
   */
  loadMembers(amount = 20) {
    if (this.offset >= this.members.length) {
      return false;
    }

    const members = this.getMembers(this.offset, amount);
    members.forEach((member) => {
      try {
        if (!this.loaded.includes(member.id)) {
          const el = MembersList.createEl(member);
          this.addMemberElToList(el);
          this.offset += 1;
          this.loaded.push(member.id);
        }
      } catch (error) {
        // console.error(error);
      }
    });

    // Finally sort the members by their IDs
    // so the order is always the same
    // this.sortMembers();

    return true;
  }

  /**
   * Create observer that loads members when entry is in view
   * 
   * @returns {IntersectionObserver}
   */
  setObserver() {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio <= 0) {
        return;
      }

      this.loadMembers();
    });

    return observer;
  }

  /**
   * Filters loaded members by checked sectors
   * 
   * @returns {Boolean}
   */
  filterMembers() {
    const loadedMembers = this.listEl.querySelectorAll('.member');
    const sectors = this.getCheckedSectors();

    this.loadMembers();

    loadedMembers.forEach((member) => {
      if (sectors.length) {
        member.classList.toggle('hide', !sectors.includes(member.dataset.sectorKey));
      } else {
        member.classList.remove('hide');
      }
    });

    return true;
  }

  /**
   * Get list of checked sectors
   * 
   * @returns {Array<String>}
   */
  getCheckedSectors() {
    return Array.from(this.filtersEl)
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.value);
  }

  /**
   * Sorts members by their ID (descending order)
   */
  sortMembers() {
    [...this.listEl.children]
      .sort((a, b) => (a.dataset.memberId < b.dataset.memberId ? 1 : -1))
      .forEach(node => this.listEl.insertBefore(node, this.triggerEl));
  }
}
